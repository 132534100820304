import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';

import { createResourceLocatorString } from '../../util/routes';

import { isScrollingDisabled } from '../../ducks/ui.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { H3, H4, Page, IconSpinner, ListingCardNew, LayoutSingleColumn } from '../../components';

import FooterComponent from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import css from './RaisedQuotes.module.css';

const isObject = value => {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
};

const matchedServices = (itemServices, currentUserServices) => {
  return itemServices.some(itemService =>
    currentUserServices.some(currentUserService => {
      return currentUserService?.value === itemService?.value;
    })
  );
};

const matchedCategories = (currentUserBusinesses, category, subCategory) => {
  return currentUserBusinesses.some(currentUserBusiness => {
    const isCategoryMatched = currentUserBusiness?.category === category;
    const isSubCategoryMatched = currentUserBusiness?.subCategory === subCategory;
    return subCategory ? isCategoryMatched && isSubCategoryMatched : isCategoryMatched;
  });
};

const isOriginInBounds = (city, country) => {
  const cityLat = city.lat;
  const cityLng = city.lng;
  const neLat = country.ne.lat;
  const neLng = country.ne.lng;
  const swLat = country.sw.lat;
  const swLng = country.sw.lng;

  return cityLat >= swLat && cityLat <= neLat && cityLng >= swLng && cityLng <= neLng;
};

const getMatchedRaisedQuotes = (raisedQuotes, currentUserData) => {
  // const matchedRaisedQuotesByLocation = raisedQuotes?.filter(raisedQuote =>
  //   Object.keys(currentUserData).some(key => {
  //     if (key === 'location' && isObject(raisedQuote[key]) && isObject(currentUserData[key])) {
  //       const originOfRaisedQuote = raisedQuote[key]?.selectedPlace?.origin;
  //       const boundsOfCurrentUser = currentUserData[key]?.selectedPlace?.bounds;
  //       return isOriginInBounds(originOfRaisedQuote, boundsOfCurrentUser);
  //     }
  //   })
  // );

  // const matchedRaisedQuotesByServices = matchedRaisedQuotesByLocation?.filter(raisedQuote =>
  const matchedRaisedQuotesByServices = raisedQuotes?.filter(raisedQuote =>
    Object.keys(currentUserData).some(key => {
      if (
        key === 'services' &&
        Array.isArray(raisedQuote[key]) &&
        Array.isArray(currentUserData[key])
      ) {
        return matchedServices(raisedQuote[key], currentUserData[key]);
      }
    })
  );

  const matchedRaisedQuotesByCategories = matchedRaisedQuotesByServices?.filter(raisedQuote => {
    const { category, subCategory } = raisedQuote;

    return Object.keys(currentUserData).some(key => {
      // if (key === 'businesses' && Array.isArray(currentUserData[key]) && category && subCategory) {
      if (key === 'businesses' && Array.isArray(currentUserData[key]) && category) {
        return matchedCategories(currentUserData[key], category, subCategory);
      }
    });
  });

  return matchedRaisedQuotesByCategories;
};

const RaisedQuotesComponent = props => {
  const intl = useIntl();
  const history = useHistory();
  const config = useConfiguration();
  const routes = useRouteConfiguration();
  const [isLoading, setIsLoading] = useState(true);
  const { listings, currentUser, searchInProgress, scrollingDisabled } = props;
  const marketplaceName = config.marketplaceName;
  const schemaDescription = intl.formatMessage({ id: 'RaisedQuotePage.schemaDescription' });
  const schemaTitle = intl.formatMessage(
    { id: 'RaisedQuotePage.schemaTitle' },
    { marketplaceName }
  );
  const {
    publicData,
    protectedData,
    services = publicData?.services,
    businesses = publicData?.businesses,
    isPremiumVendor = publicData?.isPremiumVendor,
    locationAddress = protectedData?.locationAddress,
  } = currentUser?.attributes?.profile || {};

  const currentUserData = {
    services,
    businesses,
    location: locationAddress,
    key: currentUser?.id?.uuid,
  };

  const raisedQuotes = listings?.map(listing => {
    const {
      publicData,
      category = publicData?.category,
      subCategory = publicData?.subCategory,
      subSubCategory = publicData?.subSubCategory,
      servicesType = publicData?.servicesType,
      locationFromUser = publicData?.locationFromUser,
    } = listing?.attributes || {};

    return {
      category,
      subCategory,
      subSubCategory,
      services: servicesType,
      location: locationFromUser,
      key: listing?.id?.uuid,
    };
  });

  const matchedRaisedQuotes = getMatchedRaisedQuotes(raisedQuotes, currentUserData);
  const rfqs = listings.filter(listing =>
    matchedRaisedQuotes.some(matchedRaisedQuote => matchedRaisedQuote.key === listing.id.uuid)
  );

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;

      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  useEffect(() => {
    if (currentUser) {
      if (!isPremiumVendor) {
        history.push(createResourceLocatorString('LandingPage', routes, {}, {}));
      }
    }
  }, [isPremiumVendor]);

  useEffect(() => {
    if (!searchInProgress && rfqs) {
      setIsLoading(false);
    }
  }, [rfqs]);

  return (
    <Page
      title={schemaTitle}
      description={schemaDescription}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'SearchResultsPage',
        name: schemaTitle,
        description: schemaDescription,
      }}
    >
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterComponent />}>
        <div className={css.responsiveContainer}>
          <div className={css.listingPanel}>
            {isLoading ? (
              <H3 className={css.header}>
                <FormattedMessage id="RaisedQuotePage.loadingRFQsTitle" />
              </H3>
            ) : rfqs.length > 0 ? (
              <H3 className={css.header}>
                <FormattedMessage
                  id="RaisedQuotePage.rfqsTitle"
                  values={{
                    count: rfqs.length,
                  }}
                />
              </H3>
            ) : (
              <H3 className={css.header}>
                <FormattedMessage id="RaisedQuotePage.noRFQsTitle" />
              </H3>
            )}

            {isLoading ? (
              <IconSpinner />
            ) : rfqs.length > 0 ? (
              <div className={css.listingCards}>
                {rfqs.map(l => (
                  <ListingCardNew
                    listing={l}
                    key={l.id.uuid}
                    author={l.author}
                    currentUser={currentUser}
                    renderSizes={cardRenderSizes(false)}
                  />
                ))}
              </div>
            ) : (
              <H4 className={css.noRFQ}>
                <FormattedMessage id="RaisedQuotePage.noRFQsDescription" />
              </H4>
            )}
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { currentPageResultIds, searchInProgress } = state.SearchPage;

  const listings = getListingsById(state, currentPageResultIds);

  return {
    listings,
    currentUser,
    searchInProgress,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const RaisedQuotes = compose(connect(mapStateToProps))(RaisedQuotesComponent);
RaisedQuotes.displayName = 'RaisedQuotes';

export default RaisedQuotes;
